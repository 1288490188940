import React from 'react';
import { MdArrowRightAlt } from "react-icons/md";
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap';
import Page from '../Components/Page';
import landing from '../Images/pitch.jpg'
import grass from '../Images/grass.jpg'
import { FaFacebookSquare, FaInstagramSquare, FaPhoneAlt, FaMailBulk, FaVolleyballBall, FaBirthdayCake, FaCocktail, FaEnvelope } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const submit = (e: any) => {
        e.preventDefault();
        const params = {
            formType: "Contact",
            formName: e.target["formName"].value,
            formEmail: e.target["formEmail"].value,
            formPhone: e.target["formPhone"].value,
            formLeague: e.target["formLeague"].value,
        }
        emailjs.send('service_48jl3p5', 'template_5f8x6iu', params, 'jXbPxbAIxQFI6RtcE')
        .then((result) => {
            console.log(result.text);
            window.alert("Your contact form has been submitted!")
        }, (error) => {
            console.log(error.text);
        });
    }
    return (
        <Page screen='/contact-us'>
            <>
                <Container>
                    <Row>
                        <Col className='p-0 contain contain-bottom contain__z'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2384.2846653117376!2d-1.4527582839797442!3d53.30234798570675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48798363e13a235f%3A0xaaba0d2300a920b9!2sThe%20Akademy%20Football%20Centre!5e0!3m2!1sen!2suk!4v1658993220917!5m2!1sen!2suk" width="100%" height="100%" allowFullScreen={false} loading="lazy"></iframe>
                        </Col>
                        <Col className='p-4 contain contain-bottom'>
                            <div className="d-flex flex-column">
                                <h1 className='mb-2'>Contact The Akademy</h1>
                                <p className='mb-2 p-large'>If you would like to contact us regarding any matter, please get in touch via the form below or contact us via our socials.</p>
                                <div className='d-flex flex-column mb-4'>
                                    <a href='https://www.facebook.com/theakademy/' className='navigation__link mb-2'><FaFacebookSquare size={16} /> Facebook</a>
                                    <a href='https://instagram.com/the.akademydronfield?igshid=YmMyMTA2M2Y=' className='navigation__link mb-2'><FaInstagramSquare size={16} /> Instagram</a>
                                    <a className='navigation__link mb-2'><FaPhoneAlt size={16} /> 01246 414936</a>
                                    <a className='navigation__link'><FaMailBulk size={16} /> info@akademyfootballdronfield.co.uk</a>
                                </div>
                                <Form onSubmit={submit}>
                                <Form.Group className="mb-3" controlId="formName">
                                        <p>Name</p>
                                        <Form.Control placeholder="Enter name" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <p>Email address</p>
                                        <Form.Control type="email" placeholder="Enter email" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formPhone">
                                        <p>Mobile number</p>
                                        <Form.Control placeholder="Enter mobile number" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formLeague">
                                        <p>Message</p>
                                        <Form.Control as="textarea" placeholder="Enter message" />
                                    </Form.Group>
                                    <div className="d-flex justify-content-end w-100">
                                    <Button className='navigation__link navigation__link--button navigation__link--no-margin' type='submit'><FaEnvelope size={16} /> Submit</Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        </Page>
    )
}

export default Contact