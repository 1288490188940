import React, { ReactElement } from 'react';
import Footer from './Footer';
import Nav from './Nav';

interface PageProps {
    children: ReactElement;
    screen: string;
}

const Page = ({ children, screen }: PageProps) => {
    return (
        <div className="d-flex flex-column">
            <Nav screen={screen} />
            <div className='page'>
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default Page