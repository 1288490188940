import React from 'react';
import { MdArrowRightAlt } from "react-icons/md";
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap';
import Page from '../Components/Page';
import landing from '../Images/pitch.jpg'
import children from '../Images/children.png'
import grass from '../Images/grass.jpg'
import bar from '../Images/bar-land.png'
import { FaFacebookSquare, FaInstagramSquare, FaPhoneAlt, FaMailBulk, FaVolleyballBall, FaBirthdayCake, FaCocktail } from 'react-icons/fa';

const Home = () => {
    return (
        <Page screen='/'>
            <>
                <Container>
                    <Row>
                        <Col className='p-0 contain contain-bottom contain__z'>
                            <img src={grass} className='w-100 h-auto' />
                        </Col>
                        <Col className='p-4 contain contain-bottom'>
                            <div className="d-flex flex-column">
                                <h1 className='mb-3'>The Akademy - Home of 5-a-side</h1>
                                <p className='mb-2 p-large'>The Akademy is home to the latest in 3G rubber crumb astro-turf pitches which is available for private hire and is home to our in house 5-a-side leagues!</p>
                                <p className='mb-4 p-large'>With a Club Bar and Function Suite to compliment, The Akademy is the premier Sporting and Leisure location for the Chesterfield/Sheffield area.</p>
                                <Link className='navigation__link' to='/football'><FaVolleyballBall size={16} /> View Football</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col className='p-4 contain contain-bottom mr-2'>
                             <div className='d-flex flex-column'>
                            <h3 className='mb-3 text-center'>Football pitch hire</h3>
                                <img src={landing} className='w-100 h-auto mb-3' />
                                <p>Why not get involved in one of our dedicated professionally run 5-a-side leagues? We are a leisure centre which offers very competitive rates on private pitch hires and match fees, all games take place on top of the range 3G Astro-turf which is secured by boarding, fencing and top netting.</p>
                                <Link className='navigation__link mt-3' to='/football'><FaVolleyballBall size={16} /> View more</Link>
                            </div>
                        </Col>
                        <Col className='p-4 contain contain-bottom'>
                        <div className='d-flex flex-column'>
                                <h3 className='mb-3 text-center'>Fox In The Box Bar</h3>
                                <img src={bar} className='w-100 h-auto mb-3' />
                                <ul className='bullet'>
                                    <li>120 Room capacity</li>
                                    <li>Licensed until 1AM</li>
                                    <li>Exclusive function facility</li>
                                    <li>Hire your own DJ and food or ask us to</li>
                                    <li>Car park capacity of 60 cars</li>
                                    <li>Secure overnight parking</li>
                                </ul>
                                <Link className='navigation__link mt-3' to='/main-bar-hire'><FaCocktail size={16} /> View main bar hire</Link>
                            </div>
                        </Col>
                        <Col className='p-4 contain contain-bottom ml-2 no-margin'>
                        <h3 className='mb-3 text-center'>Follow our socials</h3>
                            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftheakademy&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="100%" height="90%" scrolling="no" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col className='p-4 contain contain-bottom'>
                            <div className='d-flex flex-column'>
                                <h3 className='mb-3'>Children's Football Parties</h3>
                                <h4 className="mb-2">£130 for 10 children</h4>
                                <ul className='bullet'>
                                    <li>1 hour with football coach</li>
                                    <li>90 minutes in the function room</li>
                                    <li>Medal for every child</li>
                                    <h4 className='mb-2'>Extras</h4>
                                    <li>£10 per child if exceeded more than 10 children</li>
                                    <li>£15 for a trophy for the birthday boy/girl</li>
                                    <li>£7 per child for a personalised dominoes pizza</li>
                                </ul>
                                <Link className='navigation__link mt-3' to='/contact-us'><FaBirthdayCake size={16} /> Book a party</Link>
                            </div>
                        </Col>
                        <Col className='p-0 contain contain-bottom'>
                            <img src={children} className='w-100 h-auto' />
                        </Col>
                    </Row>
                </Container>
            </>
        </Page>
    )
}

export default Home