import React from 'react';
import { MdArrowRightAlt } from "react-icons/md";
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap';
import Page from '../Components/Page';
import landing from '../Images/pitch.jpg'
import grass from '../Images/team.jpeg'
import { FaFacebookSquare, FaInstagramSquare, FaPhoneAlt, FaMailBulk, FaVolleyballBall, FaBirthdayCake, FaCocktail, FaEnvelope } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser';

const JoinLeague = () => {

    const submit = (e: any) => {
        e.preventDefault();
        const params = {
            formType: "League",
            formName: e.target["formName"].value,
            formEmail: e.target["formEmail"].value,
            formPhone: e.target["formPhone"].value,
            formLeague: e.target["formLeague"].value,
        }
        emailjs.send('service_48jl3p5', 'template_5f8x6iu', params, 'jXbPxbAIxQFI6RtcE')
        .then((result) => {
            console.log(result.text);
            window.alert("Your league submission has been submitted!")
        }, (error) => {
            console.log(error.text);
        });
    }
    return (
        <Page screen='/join-league'>
            <>
                <Container>
                    <Row>
                    
                        <Col className='p-4 contain contain-bottom'>
                            <div className="d-flex flex-column">
                                <h1 className='mb-2'>Join our league</h1>
                                <p className='mb-2 p-large'>If you would like to contact us regarding joining a league, please get in touch via the form below or contact us via our socials.</p>
                                <div className='d-flex flex-column mb-4'>
                                    <a href='https://www.facebook.com/theakademy/' className='navigation__link mb-2'><FaFacebookSquare size={16} /> Facebook</a>
                                    <a href='https://instagram.com/the.akademydronfield?igshid=YmMyMTA2M2Y=' className='navigation__link mb-2'><FaInstagramSquare size={16} /> Instagram</a>
                                    <a className='navigation__link mb-2'><FaPhoneAlt size={16} /> 01246 414936</a>
                                    <a className='navigation__link'><FaMailBulk size={16} /> info@akademyfootballdronfield.co.uk</a>
                                </div>
                                <Form onSubmit={submit}>
                                    <Form.Group className="mb-3" controlId="formName">
                                        <p>Name</p>
                                        <Form.Control name='name' placeholder="Enter name" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <p>Email address</p>
                                        <Form.Control name='email' type="email" placeholder="Enter email" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formPhone">
                                        <p>Mobile number</p>
                                        <Form.Control name='mobile' placeholder="Enter mobile number" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formLeague">
                                        <p>League night</p>
                                        <Form.Select name='night' aria-label="Default select example">
                                            <option value="Monday">Monday</option>
                                            <option value="Wednesday">Wednesday</option>
                                            <option value="Thursday">Thursday</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <div className="d-flex justify-content-end w-100">
                                        <Button type='submit' className='navigation__link navigation__link--button navigation__link--no-margin'><FaEnvelope size={16} /> Submit</Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                        <Col className='p-0 contain contain-bottom contain__z'>
                        <img src={grass} className='w-100 h-auto' />
                                                    </Col>
                    </Row>
                </Container>
            </>
        </Page>
    )
}

export default JoinLeague