import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import JoinLeague from './Pages/JoinLeague';
import Football from './Pages/Football';
import FunctionSuite from './Pages/FunctionSuite';

function App() {
  return (
    <BrowserRouter>
    <div id="fb-root"></div>
                <script async defer src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v14.0"
                    nonce="Zj3c787q"></script>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/football' element={<Football />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path='/join-league' element={<JoinLeague />} />
        <Route path='/main-bar-hire' element={<FunctionSuite />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
