import React, { ReactElement, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from "../Images/logoWhiteTrans.png"
import { FaVolleyballBall, FaHome, FaCocktail, FaPhoneAlt, FaFileSignature } from 'react-icons/fa';
import { AiOutlineMenu } from 'react-icons/ai';

interface props {
    screen: string
}

const Nav = ({screen}: props) => {
    const mediaQuery = window.matchMedia('(max-width: 850px)')
    const [open, setOpen] = useState<boolean>(false)
    const location = useLocation();
    return (
        <div className='mobile-nav-fixed'>
            <div className="d-flex flex-row align-items-center justify-content-between navigation w-100 mobile-nav">
                <Link to="/" className="navigation__logo"><img src={logo} className="navigation__logo" /></Link>
                <div className='d-flex flex-row align-items-center mobile-nav-items'>
                    <Link className={`navigation__link ${screen === "/" && "navigation__link--active"}`} to='/'><FaHome size={16} /> Home</Link>
                    <Link className={`navigation__link ${screen === "/football" && "navigation__link--active"}`} to='/football'><FaVolleyballBall size={16} /> Football</Link>
                    <Link className={`navigation__link ${screen === "/main-bar-hire" && "navigation__link--active"}`} to='/main-bar-hire'><FaCocktail size={16} /> Main Bar Hire</Link>
                    <Link className={`navigation__link ${screen === "/join-league" && "navigation__link--active"}`} to='/join-league'><FaFileSignature size={16} /> Join League</Link>
                    <Link className={`navigation__link m-0 ${screen === "/contact-us" && "navigation__link--active"}`} to='/contact-us'><FaPhoneAlt size={16} /> Contact Us</Link>
                </div>
                {mediaQuery.matches &&
                    <AiOutlineMenu onClick={() => setOpen(!open)} size={40} color="white" />
                }
            </div>
            {(mediaQuery.matches && open) &&
                <div className='mobile-nav-actual'>
                     <div className='d-flex flex-column w-100 p-4'>
                    <Link className='navigation__link mb-3' to='/'><FaHome size={16} /> Home</Link>
                    <Link className='navigation__link mb-3' to='/football'><FaVolleyballBall size={16} /> Football</Link>
                    <Link className='navigation__link mb-3' to='/main-bar-hire'><FaCocktail size={16} /> Main Bar Hire</Link>
                    <Link className='navigation__link mb-3' to='/join-league'><FaFileSignature size={16} /> Join League</Link>
                    <Link className='navigation__link m-0' to='/contact-us'><FaPhoneAlt size={16} /> Contact Us</Link>
                </div>
                </div>
            }
        </div>
    )
}

export default Nav