import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import logo from "../Images/logoWhiteTrans.png"
import { FaVolleyballBall, FaHome, FaCocktail, FaPhoneAlt, FaFileSignature, FaFacebookSquare, FaInstagramSquare, FaMailBulk } from 'react-icons/fa';

const Footer = () => {
    return (
        <div className="d-flex flex-row justify-content-between footer-custom w-100">
            <div className='d-flex flex-row'>
                <div className='d-flex flex-column'>
                    <h4 className='mb-2'>Site links</h4>
                    <div className='d-flex flex-row'>
                        <div className='d-flex flex-column'>
                            <Link className='navigation__link mb-2' to='/'><FaHome size={16} /> Home</Link>
                            <Link className='navigation__link mb-2' to='/'><FaVolleyballBall size={16} /> Football</Link>
                            <Link className='navigation__link mb-2' to='/'><FaCocktail size={16} /> Main Bar Hire</Link>
                            <Link className='navigation__link mb-2' to='/'><FaFileSignature size={16} /> Join League</Link>
                            <Link className='navigation__link mb-2' to='/contact-us'><FaPhoneAlt size={16} /> Contact Us</Link>
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-column footer-custom-container'>
                    <h4 className='mb-2'>Get in touch</h4>
                    <div className='d-flex flex-row'>
                        <div className='d-flex flex-column'>
                            <a href='https://www.facebook.com/theakademy/' className='navigation__link mb-2'><FaFacebookSquare size={16} /> Facebook</a>
                            <a href='https://instagram.com/the.akademydronfield?igshid=YmMyMTA2M2Y=' className='navigation__link mb-2'><FaInstagramSquare size={16} /> Instagram</a>
                            <a className='navigation__link mb-2'><FaPhoneAlt size={16} /> 01246 414936</a>
                            <a className='navigation__link'><FaMailBulk size={16} /> info@akademyfootballdronfield.co.uk</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer