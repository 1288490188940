import React from 'react';
import { MdArrowRightAlt } from "react-icons/md";
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap';
import Page from '../Components/Page';
import children from '../Images/children.png'
import landing from '../Images/pitch.jpg'
import grass from '../Images/team.png'
import { FaFacebookSquare, FaInstagramSquare, FaPhoneAlt, FaMailBulk, FaVolleyballBall, FaBirthdayCake, FaCocktail, FaFileSignature } from 'react-icons/fa';

const Football = () => {
    return (
        <Page screen='/football'>
            <>
                <Container>
                    <Row>
                        <Col className='p-0 contain contain-bottom contain__z'>
                            <img src={grass} className='w-100 h-auto' />
                        </Col>
                        <Col className='p-4 contain contain-bottom'>
                            <div className="d-flex flex-column">
                                <h1 className='mb-3'>Football & Leagues</h1>
                                <p className='mb-2 p-large'>Why not get involved in one of our dedicated professionally run 5-a-side leagues? We are a leisure centre which offers very competitive rates on private pitch hires and match fees, all games take place on top of the range 3G Astro-turf which is secured by boarding, fencing and top netting.</p>
                                <p className='mb-3 p-large'>This is a great keep fit aid and promotes team building. All our leagues run all year round so your staff can stay in shape whatever the weather!</p>
                                <h4 className='mb-2'>Football league prices</h4>
                                <p>Monday to Thursday</p>
                                <p>£30 per game for 40 minutes</p>
                                <Link className='navigation__link mt-3' to='/join-league'><FaFileSignature size={16} /> Join a league</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col className='p-4 contain contain-bottom mr-2'>
                            <h3 className='mb-3 text-center'>Kids coaching</h3>
                            <ul className='bullet mb-3'>
                                <p className='mb-2'>Our kids coaching sessions take place on the finest 3G rubber crumb astro-turf.</p>
                                <li>£5 per child</li>
                                <li>Saturday</li>
                                <li>10:00AM - 11:30AM</li>
                            </ul>
                            <h4 className="mb-2">What is included:</h4>
                            <p className='mb-3'>The sessions consist of: warm-up skills, working in teams, 1v1, 2v2, penalty shoot outs, shooting skill, free kick practice, technical work and much more!</p>
                            <h4 className='mb-2'>What you'll need:</h4>
                            <p>Kit bag trainers, shin pads, water based drink, correct clothing for the time of the year, sun hats, sun creams etc.</p>
                        </Col>
                        <Col className='p-4 contain contain-bottom'>
                            <div className='d-flex flex-column'>
                                <h3 className='mb-3 text-center'>Football pitch hire</h3>
                                <ul className='bullet mb-3'>
                                    <li>Latest 3G astroturf pitches</li>
                                    <li>Changing room with showers & lockers</li>
                                    <li>Club bar open untill 11:30PM</li>
                                    <li>Secured CCTV covered car park</li>
                                </ul>
                                <h4 className='mb-2'>Peak rates</h4>
                                <p>Monday to Friday</p>
                                <p>6pm - 10pm</p>
                                <p className='mb-3'>£35 per pitch for one hour</p>
                                <h4 className='mb-2'>Off peak rates</h4>
                                <p>Monday to Friday</p>
                                <p>7am - 5pm</p>
                                <p className='mb-3'>£30 per pitch for one hour</p>
                                <h4 className='mb-2'>Weekend saver</h4>
                                <p className='mb-2'>Saturday & Sunday</p>
                                <p>7am - 5pm</p>
                                <p className='mb-2'>£20 per pitch for one hour</p>
                                <p>6pm - 10pm</p>
                                <p>£30 per pitch for one hour</p>
                            </div>
                        </Col>
                        <Col className='p-4 contain contain-bottom ml-2 no-margin'>
                            <div className='d-flex flex-column'>
                                <h3 className='mb-3 text-center'>Holiday camps</h3>
                                <p className='mb-3'>Join us for our annual Soccer Camps, Lots of Action packed fun for Boys and Girls of all abilities from 6-14 years.</p>
                                <h4 className='mb-2'>Start of the day</h4>
                                <p className='mb-3'>Shooting, Attacking,
                                    Dribbling, Defending,
                                    Passing and a lot more
                                    Action with the areas
                                    Finest coaches.</p>
                                <h4 className='mb-2'>Half time break</h4>
                                <p className='mb-3'>Don't forget your packed lunch!
                                    Soft Drinks are provided.</p>
                                <h4 className='mb-2'>In the afternoon</h4>
                                <p className='mb-3'>Mini matches,
                                    Mini Leagues
                                    Passing and a lot more
                                    All the morning skills put into Action.</p>
                                <h4 className='mb-2'>After the full time whistle</h4>
                                <p>Man of the Match awarded daily,
                                    medals for League winners etc....
                                    Great fun, Great Friends and Great Football</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col className='p-4 contain contain-bottom'>
                            <div className='d-flex flex-column'>
                                <h3 className='mb-3'>Children's Football Parties</h3>
                                <h4 className="mb-2">£130 for 10 children</h4>
                                <ul className='bullet'>
                                    <li>1 hour with football coach</li>
                                    <li>90 minutes in the function room</li>
                                    <li>Medal for every child</li>
                                    <h4 className='mb-2'>Extras</h4>
                                    <li>£10 per child if exceeded more than 10 children</li>
                                    <li>£15 for a trophy for the birthday boy/girl</li>
                                    <li>£7 per child for a personalised dominoes pizza</li>
                                </ul>
                                <Link className='navigation__link mt-3' to='/contact-us'><FaBirthdayCake size={16} /> Book a party</Link>
                            </div>
                        </Col>
                        <Col className='p-0 contain contain-bottom'>
                            <img src={children} className='w-100 h-auto' />
                        </Col>
                    </Row>
                </Container>
            </>
        </Page>
    )
}

export default Football