import React from 'react';
import { MdArrowRightAlt } from "react-icons/md";
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap';
import Page from '../Components/Page';
import landing from '../Images/bar.jpg'
import grass from '../Images/grass.jpg'
import { FaFacebookSquare, FaInstagramSquare, FaPhoneAlt, FaMailBulk, FaVolleyballBall, FaBirthdayCake, FaCocktail, FaEnvelope } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';

const FunctionSuite = () => {
    return (
        <Page screen='/main-bar-hire'>
            <>
                <Container>
                    <Row>
                        <Col className='p-0 contain contain-bottom contain__z'>
                        <img src={landing} className='w-100 h-auto' />
                        </Col>
                        <Col className='p-4 contain contain-bottom'>
                            <div className="d-flex flex-column">
                                <h1 className='mb-3'>Events & hire</h1>
                                <h4 className='mb-2'>Some events we host include</h4>
                                <ul className='bullet mb-2'>
                                    <li>Football parties</li>
                                    <li>Birthday parties</li>
                                    <li>Baby showers</li>
                                    <li>Engagement parties</li>
                                    <li>Wakes</li>
                                </ul>
                                <h4 className='mb-2'>Our main bar is perfect for hosting any special occasion</h4>
                                <p className='mb-2'>Enquire about the function suite below via phone or email.</p>
                                <ul className='bullet mb-2'>
                                    <li>120 Room capacity</li>
                                    <li>Licensed until 1AM</li>
                                    <li>Exclusive function facility</li>
                                    <li>Hire your own DJ and food or ask us to</li>
                                    <li>Car park capacity of 60 cars</li>
                                    <li>Secure overnight parking</li>
                                </ul>
                                <h4 className='mb-2'>Main bar pricing</h4>
                                <ul className='bullet mb-2'>
                                    <li>£350 exclusive main bar hire</li>
                                    <li>£250 non exclusive bar hire</li>
                                </ul>
                                <h4 className='mb-2'>Function room</h4>
                                <p className='mb-2'>Our function room is a great, affordable option for any event and boasts a capacity of 60 people. Contact us using the details below or use our contact form.</p>
                                <h4 className='mb-2'>Function room pricing</h4>
                                <ul className='bullet mb-2'>
                                    <li>£15 per hour</li>
                                </ul>
                                <div className='d-flex flex-column'>
                                    <a className='navigation__link mb-2'><FaPhoneAlt size={16} /> 01246 414936</a>
                                    <a className='navigation__link'><FaMailBulk size={16} /> info@akademyfootballdronfield.co.uk</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        </Page>
    )
}

export default FunctionSuite